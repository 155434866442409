<template>
  <v-app>
    <div class="row">
      <div class="col-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title pt-3 px-3 d-flex justify-content-between">
                <div class="breadcrumb-left">
                  <h4>Manage courses</h4>
                  <div class="breadcrumb-sub-header">
                    <router-link to="/dashboard">Dashboard</router-link> \
                    E-Learning
                    \
                    Courses
                  </div>
                </div>
                <div class="breadcrumb-right" v-if="checkIsAccessible('course', 'create')">
                  <div class="card-toolbar">
                    <v-btn @click="openDialog()" class="mt-4 btn btn-primary" style="color: #fff">
                      <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                      Add course
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body ">
              <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
                <div class="row">
                  <v-col cols="12" md="4">
                    <v-text-field
                        class="form-control"
                        outlined  v-on:keyup.enter="getCourses()"
                        v-model="search.title"
                        dense clearable
                        label="Title"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                        class="form-control"
                        outlined v-on:keyup.enter="getCourses()"
                        v-model="search.subject_code"
                        dense clearable
                        label="Subject code"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-select
                        class="form-control" v-on:keyup.enter="getCourses()"
                        v-model="search.program_id"
                        outlined
                        dense clearable
                        item-text="title"
                        item-value="id"
                        label="Program"
                        :items="programs"
                        @change="getGrades"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-select v-on:keyup.enter="getCourses()"
                        class="form-control"
                        outlined clearable
                        v-model="search.grade_id"
                        dense
                        item-text="title"
                        item-value="id"
                        :items="gradesLevels"
                        label="Grade/semester"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="12" md="8" class="text-right">
                    <v-btn
                        @click.prevent="getCourses()"
                        class="btn btn-primary btn-search w-35"
                        :loading="loading"

                    >
                      <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                      Search
                    </v-btn>
                  </v-col>
                </div>
              </div>
              <div class="">
                <v-skeleton-loader
                    type="table-thead"
                    v-if="loading"
                >
                </v-skeleton-loader>

                <v-skeleton-loader
                    v-if="loading"
                    type="table-row-divider@25"
                >
                </v-skeleton-loader>
                <table class="table" v-if="!loading">
                  <thead>
                    <tr class="px-3">
                      <th class="px-3 wrap-column">Image</th>
                      <th class="px-3 wrap-column">Subject</th>
                      <th class="px-3 wrap-column">Credit hour</th>
                      <th class="px-3 wrap-column">Program</th>
                      <th class="px-3 wrap-column">Status</th>
                      <th class="pr-3 text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-show="courses.length > 0" v-for="(course,index) in courses" :key="index.id">
                     <td class="px-3 wrap-column">
                       <span v-if="course.cover_image_path && course.cover_image_path['real']">
                         <v-img :src="course.cover_image_path['real']" style="max-width: 120px;"></v-img>
                       </span>
                       <span v-else>
                         <v-img src="https://picsum.photos/400/200/?image=180" style="max-width: 120px;"></v-img>

                       </span>

                     </td>
                      <td class="px-3 wrap-column">
                        <span class="font-weight-bolder text-hover-primary mb-1">
                          <strong>Title:</strong>
                          {{ course.title }}
                        </span>
                        <div class="mt-3">
                          <strong>Subject code:</strong>
                          <span class="font-weight-medium">
                            {{ course.subject_code ? course.subject_code : '-' }}
                          </span>
                        </div>
                      </td>
                      <td class="px-3 wrap-column">
                        <span class="font-weight-medium">
                          {{ course.total_credit_hours ? course.total_credit_hours : '-' }}
                        </span>
                      </td>
                      <td class="px-3 wrap-column">
                        <div class="text-secondary">
                          <span class="font-weight-medium">Program: </span> {{course.program.title }}
                        </div>
                        <div class="text-secondary">
                          <span class="font-weight-medium">Grade: </span> {{course.grade.title }}
                        </div>
                      </td>
                      <td class="px-3 wrap-column">
                          <span class="badge badge-success text-lg`"
                                v-bind:class="{ 'badge-success': course.is_active, 'badge-danger': !course.is_active }"
                          >{{ course.is_active ? 'Active' : 'Inactive' }}</span>
                      </td>
                      <td class="pr-0 text-center">
                        <template>
                          <b-dropdown
                              size="sm"
                              variant="link"
                              toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                              no-caret
                              right
                              no-flip
                              v-if="checkIsAccessible('course', 'edit') || checkIsAccessible('course', 'delete')"
                          >
                            <template v-slot:button-content>
                              <i class="ki ki-bold-more-hor"></i>
                            </template>
                            <!--begin::Navigation-->
                            <div class="navi navi-hover min-w-md-250px">
                              <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('course', 'edit')">
                                <a class="navi-link" @click="manageAdditionalAssessment(course.id)">
                                  <span class="navi-icon">
                                      <i class="fas fa-cog"></i>
                                  </span>
                                  <span class="navi-text">Additional assessment</span>
                                </a>
                              </b-dropdown-text>
                              <b-dropdown-text tag="div" class="navi-item"  v-if="checkIsAccessible('course', 'edit')">
                                <a class="navi-link" @click="openDialog(course.id)">
                                  <span class="navi-icon">
                                      <i class="fas fa-edit"></i>
                                  </span>
                                  <span class="navi-text">Edit</span>
                                </a>
                              </b-dropdown-text>
                              <b-dropdown-text tag="div" class="navi-item" v-if="course.lesson_plan.length">
                                <a class="navi-link" @click.prevent="manageLessonPlan(course)">
                                  <span class="navi-icon">
                                     <i class="fas fa-tasks"></i>
                                  </span>
                                  <span class="navi-text">Manage lesson plan</span>
                                </a>
                              </b-dropdown-text>
                              <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('course', 'delete')">
                                <a class="navi-link" @click.prevent="deleteCourse(course.id)">
                                  <span class="navi-icon">
                                     <i class="fas fa-trash"></i>
                                  </span>
                                  <span class="navi-text">Delete</span>
                                </a>
                              </b-dropdown-text>

                            </div>
                            <!--end::Navigation-->
                          </b-dropdown>
                          <span v-else>N/A</span>
                        </template>
                      </td>
                    </tr>
                  <tr v-if="courses.length==0">
                    <td colspan="6" class="text-center">
                      <strong> No data available to display. </strong>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <b-pagination
                    v-if="courses.length > 0"
                    class="pull-right mt-7"
                    @input="getCourses"
                    v-model="page"
                    :total-rows="total"
                    :per-page="perPage"
                    first-number
                    last-number
                    :disabled="loading"
                ></b-pagination>
              </div>
<!--              <div
            class="wizard wizard-2"
            id="kt_wizard_v2"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
        >
          <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">


            <div class="row">
              <div class="col-xl-3 col-md-4 col-sm-12" v-for="(course, index) of courses" :key="index">
                <b-card :sub-title="course.grade.title" style="max-width: 25rem;"
                        :img-src="course.cover_image_path && course.cover_image_path['real'] ? course.cover_image_path['real'] :  'https://picsum.photos/400/200/?image=180'"
                        img-alt="Image">

                  <template v-slot:header>
                    <h6 class="mb-0">{{ course.title }}
                      <b-dropdown class="pull-right"
                                  size="sm"
                                  variant="link"
                                  toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                  no-caret
                                  right
                                  no-flip
                      >
                        <template v-slot:button-content>
                          <slot>
                            <span>
                              <i class="flaticon-more-1"></i>
                            </span></slot>
                        </template>
                        &lt;!&ndash;begin::Navigation&ndash;&gt;
                        <div class="navi navi-hover ">
                          <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('course', 'edit')">
                            <a href="#" class="navi-link"
                               @click="manageAdditionalAssessment(course.id)">
                                                            <span class="navi-icon">
                                                              <i class="fas fa-cog"></i>
                                                            </span>
                              <span class="navi-text"> Additional Assessment</span>
                            </a>
                          </b-dropdown-text>
                          <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('course', 'edit')">
                            <a href="#" class="navi-link"
                               @click="openDialog(course.id)">
                                                            <span class="navi-icon">
                                                              <i class="flaticon-edit"></i>
                                                            </span>
                              <span class="navi-text">  Edit</span>
                            </a>
                          </b-dropdown-text>
                          <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('course', 'delete')">
                            <a href="#" class="navi-link"
                               @click="deleteCourse(course.id)">
                                                            <span class="navi-icon">
                                                              <i class="fas fa-trash"></i>
                                                            </span>
                              <span class="navi-text">  Delete</span>
                            </a>
                          </b-dropdown-text>

                        </div>

                      </b-dropdown>
                    </h6>

                  </template>
                  <b-card-text>
                    <b>Program:</b> {{ course.program.title }}<br>
                    <b>Subject Code:</b> {{ course.subject_code }}<br>
                    <b>Credit Hour:</b> {{ course.total_credit_hours }}<br>
                  </b-card-text>

                  <template v-slot:footer v-if="course.lesson_plan.length">
                    <router-link
                        :to="{ name:'course-summary',params:{lesson_plan_id:getLessonPlan(course.lesson_plan)}}"
                    >
                      Manage Lesson Plan
                    </router-link>
                  </template>
                  <template v-slot:footer v-else>
                    No Lesson Plan Available
                  </template>

                </b-card>
              </div>
            </div>

            <div class="row">
              <div class="col-12 text-center">
                <b-pagination
                    v-if="total>0"
                    @input="getCourses"
                    v-model="page"
                    :total-rows="total"
                    :per-page="perPage"
                    first-number
                    last-number
                ></b-pagination>

              </div>
            </div>
          </div>
        </div>&ndash;&gt;-->
            </div>
            <create-and-update ref="course" :course_id="course_id" :programId="programId" @close_dialog="closeDialog"
                         @refresh="getCourses"></create-and-update>
          </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import CreateAndUpdate from "./CreateOrUpdate"
import CoursesService from "@/core/services/courses/CoursesService";

const courseService = new CoursesService();

import GradeLevelService from "@/core/services/gradeLevel/GradeLevelService";

const gradeLevelService = new GradeLevelService();
import ProgramService from "@/core/services/level/program/ProgramService";

const programService = new ProgramService();
export default {
  name: "Courses",
  props: ["programId"],
  components: {
    CreateAndUpdate
  },
  data() {
    return {
      search: {
        program_id: "",
        grade_id: "",
        title: "",
        subject_code: "",
      },
      courses: [],
      programs: [],
      gradesLevels: [],
      course_id: null,
      isBusy: false,
      loading: false,
      page: null,
      total: 0,
      perPage: null
    };
  },
  mounted() {
    this.getPrograms();
    this.getCourses();
  },
  methods: {
    getLessonPlan(lesson_plans) {
      let lessonsPlans = lesson_plans.filter(plan => {
        return plan.is_default == true;
      })
      // console.log(lessonsPlans[0].id)
      return lessonsPlans[0].id;
    },

    getPrograms() {

      programService.all().then(response => {
        this.programs = response.data;
      }).catch((err) => {
        // console.log(err)
      }).finally(() => {

      });
    },
    getGrades() {
      gradeLevelService.getByProgram(this.search.program_id).then(response => {
        this.gradesLevels = response.data;
      });
    },
    getAllCourses() {

      courseService.all(this.search).then(response => {
        this.courses = response.data.data;
        this.page = response.data.meta.current_page;
        this.total = response.data.meta.total;
        this.perPage = response.data.meta.per_page;

      });
    },
    getCourses() {

      this.loading=true;
      courseService
          .paginate(this.search, this.page)
          .then(response => {
              this.courses = response.data.data;
              this.page = response.data.meta.current_page;
              this.total = response.data.meta.total;
              this.perPage = response.data.meta.per_page;
              this.isBusy = false;
      }).catch((err) => {

      }).finally( () => {

        this.loading=false;
      })
    },
    openDialog(id) {
      this.course_id = id;
      this.$refs["course"].showModal(id);
    },
    closeDialog(id) {
      this.course_id = id;
      this.$refs["course"].hideModal();
      this.getFaculties();
      this.course_id = null;
    },
    _reset() {
      this.search = {
        program_id: "",
        grade_id: "",
      }
      this.getPrograms();
    },
    deleteCourse(id) {
      this.$confirm({
        message: `Are you sure you want to delete this course pemanently?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        callback: confirm => {
          if (confirm) {
            courseService.delete(id).then(response => {
              this.$snotify.success("Course deleted");
              this.getCourses();
            });
          }
        }
      });
    },
    manageAdditionalAssessment(id) {
      this.$router.push({name: 'course-assessment', params: {id: id}})
    },
    manageLessonPlan(item){
      this.$router.push({name: 'course-summary', params: {lesson_plan_id: this.getLessonPlan(item.lesson_plan)}})
    }
  }
};
</script>
